:root {
  /* Colours */
  --colour-primary000: #035388;
  --colour-primary100: #0b69a3;
  --colour-primary200: #127fbf;
  --colour-primary300: #1992d4;
  --colour-primary400: #2bb0ed;
  --colour-primary500: #40c3f7;
  --colour-primary600: #5ed0fa;
  --colour-primary700: #81defd;
  --colour-primary800: #b3ecff;
  --colour-primary900: #e3f8ff;
  --colour-neutral000: #1f2933;
  --colour-neutral100: #323f4b;
  --colour-neutral200: #3e4c59;
  --colour-neutral300: #52606d;
  --colour-neutral400: #616e7c;
  --colour-neutral500: #7b8794;
  --colour-neutral600: #9aa5b1;
  --colour-neutral700: #cbd2d9;
  --colour-neutral800: #e4e7eb;
  --colour-neutral900: #f5f7fa;
  --colour-red000: #610316;
  --colour-red100: #8a041a;
  --colour-red200: #ab091e;
  --colour-red300: #cf1124;
  --colour-red400: #e12d39;
  --colour-red500: #ef4e4e;
  --colour-red600: #f86a6a;
  --colour-red700: #ff9b9b;
  --colour-red800: #ffbdbd;
  --colour-red900: #ffe3e3;
  --colour-yellow000: #8d2b0b;
  --colour-yellow100: #b44d12;
  --colour-yellow200: #cb6e17;
  --colour-yellow300: #de911d;
  --colour-yellow400: #f0b429;
  --colour-yellow500: #f7c948;
  --colour-yellow600: #fadb5f;
  --colour-yellow700: #fce588;
  --colour-yellow800: #fff3c4;
  --colour-yellow900: #fffbea;

  --spacing-small: 0.5rem;
  --spacing-base: 1rem;
  --spacing-large: 2rem;
}

html {
  font-size: 12pt;
}

@media (min-width: 801px) {
  html {
    font-size: 14pt;
  }
}

@media (min-width: 1281px) {
  html {
    font-size: 16pt;
  }
}

body {
  background-color: var(--colour-neutral000);
  color: var(--colour-neutral900);
  font-family: var(--font-family);
  font-size: var(--spacing-base);
  line-height: 1.65;
  margin: 0;
  min-height: 200vh;
  text-align: center;
}

h1,
h2,
h3 {
  font-weight: 400;
  margin: 0 0 var(--spacing-base) 0;
}

h1,
h2 {
  font-size: var(--spacing-large);
  line-height: 1.4;
}

h3 {
  font-size: 1.2rem;
  line-height: 1.3;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}
