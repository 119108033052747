:root {
  /* Colours */
  --colour-primary000: #035388;
  --colour-primary100: #0b69a3;
  --colour-primary200: #127fbf;
  --colour-primary300: #1992d4;
  --colour-primary400: #2bb0ed;
  --colour-primary500: #40c3f7;
  --colour-primary600: #5ed0fa;
  --colour-primary700: #81defd;
  --colour-primary800: #b3ecff;
  --colour-primary900: #e3f8ff;
  --colour-neutral000: #1f2933;
  --colour-neutral100: #323f4b;
  --colour-neutral200: #3e4c59;
  --colour-neutral300: #52606d;
  --colour-neutral400: #616e7c;
  --colour-neutral500: #7b8794;
  --colour-neutral600: #9aa5b1;
  --colour-neutral700: #cbd2d9;
  --colour-neutral800: #e4e7eb;
  --colour-neutral900: #f5f7fa;
  --colour-red000: #610316;
  --colour-red100: #8a041a;
  --colour-red200: #ab091e;
  --colour-red300: #cf1124;
  --colour-red400: #e12d39;
  --colour-red500: #ef4e4e;
  --colour-red600: #f86a6a;
  --colour-red700: #ff9b9b;
  --colour-red800: #ffbdbd;
  --colour-red900: #ffe3e3;
  --colour-yellow000: #8d2b0b;
  --colour-yellow100: #b44d12;
  --colour-yellow200: #cb6e17;
  --colour-yellow300: #de911d;
  --colour-yellow400: #f0b429;
  --colour-yellow500: #f7c948;
  --colour-yellow600: #fadb5f;
  --colour-yellow700: #fce588;
  --colour-yellow800: #fff3c4;
  --colour-yellow900: #fffbea;

  --spacing-small: 0.5rem;
  --spacing-base: 1rem;
  --spacing-large: 2rem;
}

html {
  font-size: 12pt;
}

@media (min-width: 801px) {
  html {
    font-size: 14pt;
  }
}

@media (min-width: 1281px) {
  html {
    font-size: 16pt;
  }
}

body {
  background-color: var(--colour-neutral000);
  color: var(--colour-neutral900);
  font-family: var(--font-family);
  font-size: var(--spacing-base);
  line-height: 1.65;
  margin: 0;
  min-height: 200vh;
  text-align: center;
}

h1,
h2,
h3 {
  font-weight: 400;
  margin: 0 0 var(--spacing-base) 0;
}

h1,
h2 {
  font-size: var(--spacing-large);
  line-height: 1.4;
}

h3 {
  font-size: 1.2rem;
  line-height: 1.3;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

/* cyrillic-ext */
@font-face {
  font-family: '__Raleway_e42c30';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/5f3f18773a22b359-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Raleway_e42c30';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/381d7763ebd33b86-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: '__Raleway_e42c30';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/40eeb93e53b2c40e-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Raleway_e42c30';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/c44159b2f652841f-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Raleway_e42c30';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/939b5075d50998ef-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Raleway_Fallback_e42c30';src: local("Arial");ascent-override: 89.62%;descent-override: 22.31%;line-gap-override: 0.00%;size-adjust: 104.89%
}.__className_e42c30 {font-family: '__Raleway_e42c30', '__Raleway_Fallback_e42c30';font-style: normal
}.__variable_e42c30 {--font-family: '__Raleway_e42c30', '__Raleway_Fallback_e42c30'
}

